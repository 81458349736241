import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`株式会社`}</h4>
    <p><img parentName="p" {...{
        "src": "./logo-white.png",
        "alt": "sanyoya",
        "title": "sanyoya"
      }}></img>{` `}<img parentName="p" {...{
        "src": "./title-white.png",
        "alt": "sanyoya",
        "title": "sanyoya"
      }}></img></p>
    <p>{`温泉ホテルや旅行向けのデジタルソリューションの提供に重点を置いたIT企業です。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      