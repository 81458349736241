import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`会社情報`}</h2>
    <blockquote>
      <p parentName="blockquote">{`株式会社山月夜は、温泉ホテルや旅行代理店向けの高度なインターネットソリューションの提供に取り組んでいます`}</p>
    </blockquote>
    <p>{`温泉旅行文化を広めるために、海外の観光客のために日本の温泉へのより包括的な旅行ガイドを提供する。温泉ホテルの海外旅行者向けオンライン予約システム、顧客関係管理システムの開発。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      