import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`メディア報道`}</h2>
    <ProjectCard title="Resigned to a new way of life" link="https://www.chinadaily.com.cn/a/202007/09/WS5f065f97a3108348172582e7.html" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
China Daily 2020-07-09
    </ProjectCard>
    <ProjectCard title="28歳の引退したプログラマ郭宇：今私は選択できます
" link="https://mp.weixin.qq.com/s/PrYSlD-TsTnjJay5RM0SWw" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
《人物》2020-06-28
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      